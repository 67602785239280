import { createContext, useState, useEffect } from 'react';

export const PathwayContext = createContext({
    track: (_: string, __: Opts) => {},
    page: (_: Data) => {},
});

interface Props {
    children: React.ReactElement;
}

interface Opts {
    properties?: {};
    options?: {};
    callback?: () => void;
}

interface Data {
    category?: string;
    name?: string;
    properties?: {};
    options?: {};
    callback?: () => void;
}

export interface PathwayProps {
    track: (event: string, opts: Opts) => void;
    page: (data: Data) => void;
}

const noop = () => {};

const Pathway: React.FC<Props> = ({ children }) => {
    const [pathway, getPathway] = useState<PathwayProps>({
        track: noop,
        page: noop,
    });

    const setState = () => {
        // @ts-ignore
        getPathway(window.pathway?.default);
    };

    useEffect(() => {
        if (typeof window !== undefined) {
            setTimeout(setState, 1000);
        }
    }, []);

    return (
        <PathwayContext.Provider value={pathway}>
            {children}
        </PathwayContext.Provider>
    );
};

export default Pathway;
