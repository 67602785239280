import React, { useEffect, useState } from 'react';
import { signIn, signOut } from 'next-auth/react';
import { useSession } from 'next-auth/react';
import { Link } from '@mdb/flora';
import { UnifiedNavCustom } from '../styled/consistent-nav';
import { useBasePath } from '../lib/path';

const ConsistentNav: React.FunctionComponent = () => {
    const { data: session } = useSession();

    const navItems = [
        {
            title: 'statistics',
            path: useBasePath('/stats'),
        },
        {
            title: 'students',
            path: useBasePath('/'),
        },
        {
            title: 'load codes',
            path: useBasePath('/loadcodes'),
        },
    ];

    const [signInOutLinkCreated, setSignInOutLinkCreated] = useState(false);
    const [signInOutButtonCreated, setSignInOutButtonCreated] = useState(false);

    // Desktop Signed In State
    useEffect(() => {
        if (!signInOutLinkCreated) {
            const signInOutLink = document.createElement('a');
            const searchElement = document.querySelector(
                '[aria-label="Open Search"]'
            ) as Element;
            signInOutLink.id = 'sign-in-out-link';
            signInOutLink.style.cssText += 'margin-right:20px;cursor:pointer';
            searchElement.parentNode?.insertBefore(
                signInOutLink,
                searchElement.nextSibling
            );
            setSignInOutLinkCreated(true);
        }
        const signInOutLink = document.getElementById('sign-in-out-link');
        if (signInOutLink) {
            signInOutLink.innerHTML = session
                ? 'Log Out of GitHub'
                : 'Sign In with GitHub';
            signInOutLink.onclick = session
                ? () => signOut()
                : () => signIn('github');
        }
    }, [session, signInOutLinkCreated]);

    // Mobile Signed In State
    useEffect(() => {
        if (!signInOutButtonCreated) {
            const menuWrapper = document.querySelectorAll(
                '[role="menu-wrapper"]'
            )[0];
            const signInOutButton = document.createElement('button');
            signInOutButton.id = 'sign-in-out-button';
            signInOutButton.style.cssText += 'margin:20px;';
            signInOutButton.className = 'secondary-btn';
            menuWrapper.appendChild(signInOutButton);
            setSignInOutButtonCreated(true);
        }
        const signInOutButton = document.getElementById('sign-in-out-button');
        if (signInOutButton) {
            signInOutButton.innerHTML = session
                ? 'Log Out of GitHub'
                : 'Sign In with GitHub';
            signInOutButton.onclick = session
                ? () => signOut()
                : () => signIn('github');
        }
    }, [session, signInOutButtonCreated]);

    return (
        <>
            <UnifiedNavCustom
                position="sticky"
                floraTheme="default"
                hideTryFree
                hideSignIn
            />
            {session?.admin && (
                <div sx={{ display: 'flex', justifyContent: 'end' }}>
                    {navItems.map(item => (
                        <Link
                            key={item.title}
                            sx={{ marginRight: '20px' }}
                            href={item.path}
                        >
                            {item.title}
                        </Link>
                    ))}
                </div>
            )}
        </>
    );
};

export default ConsistentNav;
