import React, { useContext, useEffect } from 'react';
import { Global } from '@emotion/react';
import { UnifiedFooter } from '@mdb/consistent-nav';

import { useBasePath } from '../lib/path';
import { globalStyles, Main } from '../styled/layout';

import ConsistentNav from './ConsistentNav';
import { PathwayContext, PathwayProps } from 'src/context/pathway-context';

const Layout: React.FunctionComponent<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    const backgroundImageURL = useBasePath('/background.svg');

    const pathway = useContext<PathwayProps>(PathwayContext);

    // for mdb track method
    useEffect(() => {
        if (typeof window !== undefined) {
            (window as any).mdbTrack = (event: string, props: any) => {
                pathway.track(event, props);
            };
        }
    }, []);

    return (
        <>
            <Global styles={globalStyles} />
            <ConsistentNav />
            <Main
                sx={{
                    backgroundImage: `url('${backgroundImageURL}')`,
                    backgroundRepeat: 'no-repeat',
                    overflowX: 'hidden',
                }}
            >
                <div sx={{ width: '90%', margin: '0 auto' }}>{children}</div>
            </Main>
            <UnifiedFooter hideLocale />
        </>
    );
};

export default Layout;
