type URLPath = `/${string}`;

// Keep this in sync with basePath value specified in next.config.js
const basePath = '/students';

export function useBasePath(path: URLPath): string {
    return `${basePath}${path}`;
}

// Prefer useBasePath in components, but yoyu'll have to use withBasePath
// for cases where you can't use hooks.
export function withBasePath(path: URLPath): string {
    return `${basePath}${path}`;
}
