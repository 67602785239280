import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import theme from '@mdb/flora/theme';
import { ThemeProvider } from '@theme-ui/core';
import Pathway from '../context/pathway-context';
import { useBasePath } from '../lib/path';
import '../styles.css';
import Layout from '../components/Layout';

function MyApp({ Component, pageProps }: AppProps) {
    const authPath = useBasePath('/api/auth');
    const faviconPath = useBasePath('/favicon.ico');

    return (
        <SessionProvider
            session={pageProps.session}
            refetchInterval={0}
            basePath={authPath}
        >
            <Head>
                <title>MongoDB Student Pack</title>
                <meta name="description" content="MongoDB Student Pack" />
                <link rel="icon" href={faviconPath} />
            </Head>
            {/* Calling Google Tag manager only in production environments allows Cypress not to fail when GTM is blocked */}
            {!process.env.NEXT_PUBLIC_SKIP_GTM && (
                <Script
                    id="pathway-script"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: ` !function (e, n) { 
                        var t = document.createElement("script"), 
                        o = null, 
                        x = "pathway"; 
                
                        t.async = !0, 
                        t.src = 'https://' + x + '.mongodb.com/' + (e ? x + '-debug.js' : ''), 
                        document.head.append(t), 
                        t.addEventListener(
                            "load", 
                            function () {
                                o = window.pathway.default, 
                                (n && o.configure(n)), 
                                o.createProfile("mongodbcom").load(), 
                                window.segment = o 
                            }) 
                    }${
                        process.env.NODE_ENV === 'production'
                            ? '()'
                            : '(!0,{debugMode:!0})'
                    };`,
                    }}
                />
            )}
            <ThemeProvider theme={theme}>
                <Pathway>
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                </Pathway>
            </ThemeProvider>
        </SessionProvider>
    );
}

export default MyApp;
