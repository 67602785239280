var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"k4KQxUb44oj99pyRcsDVt"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/students";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
    dsn: 'https://67647f4c762c4804a8acee1b1a85b4b3@o4504991346720768.ingest.sentry.io/6459062',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.1,
    // NOTE: analytics scripts and crawlers are setting off a bunch of different TypeErrors
    // and it is not possible to use the whitelist since these errors are originated from
    // the main site. beforeSend doesn't seem to work for these events either and since
    // this application is not actively modified, we will sacrifice this specific TypeError
    // which occurs ~7k times p/ month and is a false alarm.
    // see https://mongodb-org.sentry.io/issues/4649597403/?environment=production&project=6459062&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=7d&stream_index=9
    ignoreErrors: [
        '<unknown>',
        "TypeError: Cannot read properties of undefined (reading 'includes')",
    ],
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
